.privacy-policy-container {
    text-align: start;
    margin: 8px;
    font-family: serif;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    /* margin-block-end: 0.67em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h2 {
    display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        /* margin-block-end: 0.83em; */
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}
p{
    display: block;
        margin-block-start: 1em;
        /* margin-block-end: 1em; */
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
ul{
    display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
}
li {
        display: list-item;
            text-align: -webkit-match-parent;
}
h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}